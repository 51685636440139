<template>
  <ul v-if="0 !== reviews.length" class="reviews">
    <li v-for="review in reviews" class="review-item">
      <div class="review-item__header">
        <div class="review-item__user">
          <img
            src="~~/assets/icons/user-photo.svg"
            alt="user photo"
            class="review-item__user-photo"
            loading="lazy"
          />
          <p class="review-item__user-name">
            {{ review.author.name }}
          </p>
          <time
            :datetime="new Date(review.createdAt * 1000).toISOString()"
            class="review-item__date"
          >
            {{ getFormatDate(review.createdAt) }}
          </time>
        </div>
        <NuxtRating
          :rating-value="review.rating"
          :rating-size="16"
          active-color="#fbbd09"
        />
      </div>
      <div class="review-content" v-html="urlify(review.content)" />
      <div class="review-item__box">
        <div class="review-item__advantages">
          <img
            class="review-item__description-icon"
            src="~~/assets/icons/face-smile.svg"
            alt="smile"
            loading="lazy"
          />
          <div class="review-item__description">
            <p class="review-item__description-title green">
              {{ _T("@Product liked") }}
            </p>
            <p class="review-item__description-item">
              {{ review.pros ?? _T("@no") }}
            </p>
          </div>
        </div>
        <div class="review-item__disadvantages">
          <img
            class="review-item__description-icon"
            src="~~/assets/icons/face-sad.svg"
            alt="sad"
            loading="lazy"
          />
          <div class="review-item__description">
            <p class="review-item__description-title red">
              {{ _T("@Product flaws") }}
            </p>
            <p class="review-item__description-item">
              {{ review.cons ?? _T("@no") }}
            </p>
          </div>
        </div>
      </div>

      <div v-if="null !== review.reply" class="review-item__answer">
        <div class="review-item__answer-line">
          <div class="review-item__header">
            <div class="review-item__user">
              <img
                src="/img/review-company-logo.svg"
                alt="answer photo"
                class="review-item__user-photo"
                loading="lazy"
              />
              <p class="review-item__user-name">{{ _T("@Trademark") }}</p>
              <time
                class="review-item__date"
                :datetime="
                  new Date(review.reply.createdAt * 1000).toISOString()
                "
              >
                {{ getFormatDate(review.reply.createdAt) }}
              </time>
            </div>
          </div>
          <div class="review-content" v-html="urlify(review.reply.content)" />
        </div>
      </div>
    </li>
  </ul>
</template>

<script setup>
const props = defineProps({
  reviews: { type: Array, required: true },
});

const getFormatDate = (createdAt) =>
  useState("review date" + createdAt, () => {
    return new Intl.DateTimeFormat("uk-UA", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    })
      .format(createdAt * 1000)
      .replace(",", "");
  });

const urlify = (text) => {
  const urlRegex =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,512}\.[a-z]{2,6}\b([-a-zA-Z0-9:%_+~#&/=]*)/g;

  return text.replace(
    urlRegex,
    (url) => '<a href="' + url + '">' + url + "</a>",
  );
};
</script>

<style lang="scss" scoped>
.reviews {
  width: 100%;

  @include flex-container(column);
  gap: 16px;
}

.review-item {
  width: 100%;

  @include flex-container(column);
  gap: 16px;

  background-color: white;
  border-radius: 8px;
  @extend %shadow;

  padding: 16px;

  &__header {
    @include flex-container(column);
    gap: 8px;

    margin-bottom: 8px;
  }

  &__user {
    position: relative;
    @include flex-container(row, flex-start, center);
    gap: 8px;
  }

  &__user-photo {
    width: 32px;
    height: 32px;
  }

  &__user-name {
    @include font(16, 22, 500);
    letter-spacing: 0.32px;
    color: var(--color-ink-lightest);
  }

  &__date {
    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;

    @include font(12, 16);
    letter-spacing: 0.24px;
    color: var(--color-sky-darker);
  }

  &__box {
    @include flex-container(row, flex-start);
    gap: 42px;

    margin-bottom: 8px;
  }

  &__advantages,
  &__disadvantages {
    @include flex-container(row, flex-start);
    gap: 8px;
  }

  &__description-icon {
    width: 24px;
    height: 24px;
  }

  &__description {
    @include flex-container(column, flex-start);
    gap: 8px;
  }

  &__description-title {
    @include font(12, 16, 700);
    letter-spacing: 0.24px;

    &.green {
      color: #329138;
    }

    &.red {
      color: #e25750;
    }
  }

  &__description-item {
    max-width: 247px;
    width: 100%;

    @include font(12, 16, 400);
    letter-spacing: 0.24px;
  }

  &__answer {
    border-top: 1px solid var(--color-sky-dark);
    padding-top: 24px;

    & .review-item__user-name {
      color: var(--color-primary-base);
    }
  }

  &__answer-line {
    border-left: 1px solid var(--color-primary-base);

    padding-left: 16px;
    margin-left: 32px;
  }
}
</style>
